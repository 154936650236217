#main-toolbar {
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

#account-toolbar {
    height: $account-toolbar-height;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--mat-sidenav-container-divider-color);
}