@use '@angular/material' as mat;
.custom-card {
    padding: 24px; 
    border-radius: 12px !important;
    // @include mat.elevation(18);
    box-shadow: 0px 12px 24px 2px rgba(0, 0, 0, 0.06) !important;
    &_actions {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
        display: flex;
        gap: 16px;
        &-icon {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            cursor: pointer;
        }
    }
}

.card-bordered {
    border-radius: 12px !important;
}