@use 'sass:map';
@use '@angular/material' as mat;


@mixin theme-reset($theme, $dark-theme: false) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, 'primary');
    $accent: map.get($color-config, 'accent');
    $warn: map.get($color-config, 'warn');
    $foreground: map.get($color-config, 'foreground');
    $background: map.get($color-config, 'background');

    .bg {
        background-color: mat.m2-get-color-from-palette($background, background);
    }

    .bg-primary {
        background: mat.m2-get-color-from-palette($primary);
        color: mat.m2-get-color-from-palette($primary, default-contrast);
    }

    .bg-accent {
        background: mat.m2-get-color-from-palette($accent);
        color: mat.m2-get-color-from-palette($accent, default-contrast);
    }

    .bg-warn {
        background: mat.m2-get-color-from-palette($warn);
        color: mat.m2-get-color-from-palette($warn, default-contrast);
    } 

    .bg-card {
        background-color: mat.m2-get-color-from-palette($background, card);
    }

    .bg-white:not(.mat-mdc-form-field) {
        background-color: #fff;
    }

    .bg-grey:not(.mat-mdc-form-field) {
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-grey-palette, 100));
    }

    .bg-red:not(.mat-mdc-form-field) {
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-red-palette, 100));
    }

    .bg-green:not(.mat-mdc-form-field) {
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-green-palette, 100));
    }

    .bg-blue:not(.mat-mdc-form-field) {
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-blue-palette, 100));
    }

    .bg-white.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        border-radius: 6px;
        background-color: #fff;
    }

    .bg-grey.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        border-radius: 6px;
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-grey-palette, 100));
    }

    .bg-red.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        border-radius: 6px;
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-red-palette, 100));
    }

    .bg-green.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        border-radius: 6px;
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-green-palette, 100));
    }

    .bg-blue.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        border-radius: 6px;
        background-color: mat.m2-get-color-from-palette(mat.m2-define-palette(mat.$m2-blue-palette, 100));
    }


    .primary-color {
        color: mat.m2-get-color-from-palette($primary);
    }

    .accent-color {
        color: mat.m2-get-color-from-palette($accent);
    }

    .warn-color {
        color: mat.m2-get-color-from-palette($warn);
    }

    .base-color {
        color: mat.m2-get-color-from-palette($foreground, base) !important;
    }

    .secondary-color {
        color: mat.m2-get-color-from-palette($foreground, secondary-text) !important;
    }

    .primary-border {
        border-color: mat.m2-get-color-from-palette($primary);
    } 

    .accent-border {
        border-color: mat.m2-get-color-from-palette($accent);
    } 

    .default-contrast {
        color: mat.m2-get-color-from-palette($primary, default-contrast) !important; 
    }

    .menu-item { 
        svg path {
            stroke: mat.m2-get-color-from-palette($foreground, secondary-text);
        } 
    }

    .active-link {
        background-color: mat.m2-get-color-from-palette($primary); 
        color: #fff;
        svg path {
            stroke: #fff;
        }
    }

    .sub-menu {
        .active-link {
            background-color: mat.m2-get-color-from-palette($background, card);
            color: mat.m2-get-color-from-palette($primary); 
        }
    }

    .theme-pagination .ngx-pagination .current {
        background: mat.m2-get-color-from-palette($primary); 
    }
 
    $alert-bg-opacity: 1;
    $menu-title-color: mat.m2-get-color-from-palette($primary);
    $bg-light: #F5F7FA;
    $svg-color: #4D5761;
    $dark-blue-1: #384D65;

    @if $dark-theme {
        $alert-bg-opacity: .12;
        $menu-title-color: #fff;
        $bg-light: #424242;
        $svg-color: #F5F7FA;
        $dark-blue-1: #91a7c0;
    }
    .bg-light {
        background-color: $bg-light;
    }
    .custom-icon {
        stroke: $svg-color;
    }

    .dark-blue-1 {
        color: $dark-blue-1;
    }


    .ngx-pagination a, 
    .ngx-pagination button {
        color: mat.m2-get-color-from-palette($foreground, base);
    }


    .alert {
        color: #000;
    }
 


     /* ngx-quill */ 
    .ql-snow .ql-picker,
    .ql-editor.ql-blank::before{
        font-style: normal;
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }
    .ql-snow .ql-stroke{
        stroke: mat.m2-get-color-from-palette($foreground, text);
    }
    .ql-snow .ql-fill{
        fill: mat.m2-get-color-from-palette($foreground, text);
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, 
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
        background: mat.m2-get-color-from-palette($background, app-bar);
    }



    .view-steps { 
        background-color: mat.m2-get-color-from-palette($background, card);
        a { 
            &:after { 
                background-color: mat.m2-get-color-from-palette($background, card); 
            }
            &:hover,
            &.active,
            &.active:after, 
            &:hover:after {
                background: mat.m2-get-color-from-palette($accent);
                color: mat.m2-get-color-from-palette($primary, default-contrast);
            }
        }
    } 


    .payment-options .mat-mdc-radio-button { 
        &.mat-mdc-radio-checked {
            .mat-internal-form-field {
                border-color: mat.m2-get-color-from-palette($primary);
            } 
        } 
    }
    
    // Make image radius 50%
    img.img-radius-50 {
        border-radius: 50%;
      }

}