$spinner-color: #00c853;
.spinner-wrapper{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1; 
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;
    &.hide{
        visibility: hidden;
        opacity: 0; 
    }
    .spinner-container{
        height:100%;
        width:100%;
        display:table;
        .spinner-outer{
            vertical-align:middle;
            height:100%;
            display:table-cell;
            .spinner {
                animation: rotate 2s linear infinite;
                z-index: 2;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -60px 0 0 -60px;
                width: 120px;
                height: 120px;                
                .path {
                  stroke: $spinner-color;
                  animation: dash 1.5s ease-in-out infinite;
                } 
            } 
        }
    }
}   

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
} 
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
