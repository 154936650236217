.theme-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    padding: 0 16px;
    border-color: var(--mdc-outlined-card-outline-color);

    thead tr {
        height: 38px;
    }

    tbody tr {
        height: 34px;
        font-size: 13px;

        &:last-child td {
            border-bottom: none;
        }
    }

    thead tr th,
    tbody tr td {
        padding: 0 12px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--mdc-outlined-card-outline-color); 
        box-sizing: border-box;

        &:last-child {
            border-right: none;
        }
    }

    thead tr th {
        font-weight: 600;
        text-align: left;
        font-size: 16px; 
        white-space: nowrap;
        position: relative;
        height: 80px;
    }

    tbody tr td {
        font-weight: 500;
        text-align: left;
        font-size: 16px;  
        height: 100px;
    }
}