.mat-mdc-tab.mdc-tab,
.mat-mdc-tab-link.mdc-tab {
    padding: 0;
}
.mat-mdc-tab-labels,
.mat-mdc-tab-links {
    gap: 16px;
}

.feed-tabs .mat-mdc-tab-label-container {
    padding: 8px 24px;
}