@import '../abstracts/functions';

.flex {
    display: flex;
    box-sizing: border-box;
}

.inline-flex {
    display: inline-flex;
    box-sizing: border-box;
}

.flex-row {
    display: flex;
    flex-direction: row !important;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.flex-col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-fill {
    flex: 1 1 0%;
    box-sizing: border-box;
}

@for $i from 1 through 100 {
    .flex-#{$i} {
        flex: 1 1 #{rounded(calc(100% / $i*1), 5)};
        box-sizing: border-box;
        max-width: #{rounded(calc(100% / $i*1), 5)};
    }
}

.flex-container-2 {
    margin: 0 -8px;
}
.flex-container-3 {
    margin: 0 -16px;
}
.flex-container-4 {
    margin: 0 -24px;
}
.flex-spacer {
    flex: 1 1 auto;
}

//Justify-Content
.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-stretch {
    justify-content: stretch;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

//Align-Items
.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.align-center {
    align-items: center
}

.align-stretch {
    align-items: stretch
}

.align-baseline {
    align-items: baseline;
}

.center-center {
    justify-content: center;
    align-items: center;
}