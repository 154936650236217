@use '@angular/material' as mat;
@use 'sass:map'; 
@import '../../../node_modules/ngx-multiple-dates/theming'; // import library theme

$custom-typography: mat.m2-define-typography-config( 
    $font-family: $font-family,
    $body-1: mat.m2-define-typography-level(14px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 500, $letter-spacing: 0),
); 

@include mat.all-component-typographies($custom-typography);
@include mat.core();

@import 'create-light-theme', 'create-dark-theme', 'theme-reset';

#app {  
    &.green-light { 
        @import "skins/light/green-light";
        @include mat.all-component-colors($green-light-theme); 
        @include theme-reset($green-light-theme);
        @include mat.all-component-densities(0);
        @include mat.form-field-density(-2);
        // @include mat.button-density(-2);
        @include ngx-multiple-dates-theme($green-light-theme); 
    }  
    &.green-dark{
        @import "skins/dark/green-dark";
        @include mat.all-component-colors($green-dark-theme);
        @include theme-reset($green-dark-theme, true);
        @include mat.all-component-densities(0);
        @include mat.form-field-density(-2);
        @include ngx-multiple-dates-theme($green-dark-theme); 
    } 
} 

#app.green-light,
#app.green-dark { 
    --mdc-filled-button-container-shape: 6px; 
    --mdc-outlined-button-container-shape: 6px; 
    --mat-toolbar-standard-height: 80px;
    --mat-expansion-container-shape: 12px;
    --mdc-dialog-container-shape: 12px;
}

#app.green-light {  
    // --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.24);
    // --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.24);
    // --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.24);
    // --mat-divider-color: rgba(0, 0, 0, 0.24);
    --mdc-outlined-button-outline-color: #E5E7EB;
    --mdc-outlined-card-outline-color: #E5E7EB;
    --mdc-outlined-text-field-outline-color: #E5E7EB;
    --mat-divider-color: #E5E7EB;
}

#app.green-dark {  
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.24);
    --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.24);
    --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.24);
    --mat-divider-color: rgba(255, 255, 255, 0.24);
    // --mdc-outlined-text-field-outline-color: #D2D6DB;
}
