.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs{
    font-size: 14px !important;
    line-height: 14px !important;
    height: 14px !important;
    width: 14px !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.icon-sm {
    font-size: 18px !important;
    line-height: 18px !important;
    height: 18px !important;
    width: 18px !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.icon-md {
    font-size: 24px !important;
    line-height: 24px !important;
    height: 24px !important;
    width: 24px !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg{
    font-size: 36px !important;
    line-height: 36px !important;
    height: 36px !important;
    width: 36px !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg{
    font-size: 48px !important;
    line-height: 48px !important;
    height: 48px !important;
    width: 48px !important;
}