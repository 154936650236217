.mdc-menu-surface.mat-mdc-select-panel.search-dropdown{
    overflow: hidden;
    padding: 36px 0 0;
    .search-input{
        position: absolute;
        padding: 0 16px;
        box-sizing: border-box;
        top: 0;
        height: 36px;
        width: 100%;
        border: 0;
        outline: none;
        font-size: 16px;
        background: transparent; 
        &::placeholder { 
            opacity: 0.6;
        } 
    }
    .search-container{
        max-height: 230px;
        overflow: auto;
    }
}

.mat-mdc-select-arrow {
    svg {
        display: none;
    }
    &:after {
        content: '';
        border-style: solid;
        border-width: 0.13em 0.13em 0 0;           
        display: inline-block;
        height: 0.55em; 
        position: relative;
        top: -4px; 
        vertical-align: top;
        width: 0.55em;
        margin: 0;
        transform: rotate(135deg);
    }
}
 
.mat-mdc-form-field {
    &.pb-0 {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
    &.rounded-corner {
        --mdc-outlined-text-field-container-shape: 24px  ;
    }
    &.custom-select-btn {
        .mdc-notched-outline__leading {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        } 
    }
}


ngx-multiple-dates input.mat-datepicker-input {
    color: transparent !important;
}

// .mdc-text-field--outlined {
//     --mdc-outlined-text-field-container-shape: 28px !important;
//   }