.payment-options .mat-mdc-radio-button {
    .mat-internal-form-field {
        border: 1px solid var(--mdc-outlined-card-outline-color);
        border-radius: 8px;
        padding: 8px 24px 24px 8px;
        width: 312px;
        height: 88px;
        box-sizing: border-box;
        >label {
            width: 100%;
        }
    } 
} 