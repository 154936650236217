.lg-btn {
    height: 48px !important;
}

.rounded-btn {
    border-radius: 20px !important;
}

.left-aligned-btn {
    .mdc-button__label {
        width: 100%;
        text-align: left;

    }
}

button.square-icon-btn,
a.square-icon-btn {
    min-width: 40px;
    width: 40px;
    height: 40px;
    padding: 0;
}

.custom-select-btn {
    button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 112px;
        white-space: nowrap;
    }
}