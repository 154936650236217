@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme-or-color-config) {
  .mat-calendar-body-cell.selected {
    &,
    &:hover,
    &:not(.mat-calendar-body-disabled),
    &:not(.mat-calendar-body-disabled):hover {
      .mat-calendar-body-cell-content {
        &,
        &:hover,
        &:not(.disabled),
        &:not(.disabled):hover {
          background-color: mat.get-theme-color($theme-or-color-config, primary, 50) !important;
          color: mat.get-theme-color($theme-or-color-config, primary, 98) !important;
        }
      }
    }
  }
}
