/******* Responsive *******/ 
// xs
@media screen and (max-width: 599.99px) {
    
} 

// sm
@media screen and (min-width: 600px) and (max-width: 959.99px) {
     
}

// md
@media screen and (min-width: 960px) and (max-width: 1279.99px) { }

// lg
@media screen and (min-width: 1280px) and (max-width: 1919.99px) { }

// xlg
@media screen and (min-width: 1920px) { }