@import 'styles/spinner';
@import 'styles/fonts/montserrat';
@import 'styles/abstracts/variables';
@import 'styles/abstracts/functions'; 
@import 'styles/themes/theme';
@import "styles/layout";
@import "styles/components";

@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');
quill-editor {
    width: 100%;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border-color: var(--mdc-outlined-card-outline-color);
}
.ql-toolbar.ql-snow {
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
   background: var(--mdc-outlined-card-outline-color);
}
.ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.job-desc {
    .ql-container.ql-snow{
        min-height: 120px;
        margin-bottom: 8px;
    }
}

bar-rating {
    --br-active-color: #FDB90A !important;
    --br-inactive-color:#e0e0e0 !important;
    // --br-inactive-color:#0000001f !important;
}

// :host ::ng-deep .star .br-unit {
//     --br-active-color: #FDB90A;
//     --br-inactive-color:rgb(175, 5, 5);
// }

@import "../node_modules/dropzone/dist/min/dropzone.min.css"; 
.dropzone {
    border: 1px solid var(--mdc-outlined-card-outline-color);
    min-height: 220px;
    border-radius: 6px;

    .dz-preview {
        .dz-remove {
            font-size: 12px;
            border: 1px solid #D2D6DB;
            border-radius: 8px;
            margin-top: 4px;
        }

        &.dz-image-preview {
            background: transparent;
        }

        .dz-error-mark,
        .dz-success-mark {
            margin-top: -38px;
        }
    }
    .dz-message {
        text-align: center; 
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        place-content: center; 
        min-height: 176px;
    }

    &.upload-files {
        border: 1px dashed var(--mdc-outlined-card-outline-color); 
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        .dz-message {
            position: absolute;
            z-index: 9;
            width: 100%;
            height: 100%; 
            top: 0;
            left: 0;
        }
    }
}


:root {
    --ng-progress-thickness: 3;
    --ng-progress-color: #00c853;
}