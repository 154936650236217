.badge {
    border-radius: 16px;
    height: 24px;
    display: flex;
    align-items: center;
    place-content: center;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    background-color: #6c737f;
    color: #fff;
    &.primary {
        background-color: #00c853;
    }
    &.red {
        background-color: #f44336;
    }
    &.urgent {
        background-color: #d50000;
    }
    &.green {
        background-color: #147b3f;
    }
    &.blue {
        background-color: #2196f3;
    }
    &.gray {
        background-color: #E5E7EB;
        color: #384250;
    }
    &.yellow {
        background-color: #FCEFBF;
        color: #ECB306;
    }
    &.lg {
        height: 36px;
    }
}

.rounded-badge {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    align-items: center;
    place-content: center;
    font-size: 10px;
    font-weight: 600;
    &.red {
        background-color: #f44336;
    }
}