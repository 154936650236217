* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: $font-family;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    padding: 16px;
    li:not(:last-child) {
        margin-bottom: 16px;        
    }
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 32px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

.body-1 {
    font-size: 24px;
}

.body-2 {
    font-size: 20px;
}

.body-3 {
    font-size: 18px;
}

.body-4 {
    font-size: 16px;
}

.body-5 {
    font-size: 14px;
}

.body-6 {
    font-size: 12px;
}

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.fw-semi-bold {
    font-weight: 600;
}

.fw-bold {
    font-weight: 700;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-truncated {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.w-100 {
    width: 100%;
}

.mw-100 {
    max-width: 100%;
}

.h-100 {
    height: 100%;
}

.d-block {
    display: block;
}

.ws-nowrap {
    white-space: nowrap;
}

.bordered {
    border: 1px solid var(--mdc-outlined-card-outline-color) !important;
}

.pointer {
    cursor: pointer;
}

.rounded {
    border-radius: 50%;
}

.bx-shadow-none {
    box-shadow: none !important;
}

.o-hidden {
    overflow: hidden !important;
}

.m-auto {
    margin: 0 auto;
}

.text-underline {
    text-decoration: underline;
}

.theme-container {
    max-width: $theme-max-width;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16px;
}

/******* APP *******/
#app {
    height: 100%;

    .page-wrapper {
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        place-content: stretch flex-start;
        align-items: stretch;
        max-width: 100%;
        min-height: 100vh;

        .sticky-footer-helper {
            flex: 1 1 100%;
            box-sizing: border-box;
            max-height: 100%;
        }
    }

    &.main-toolbar-fixed {
        #main-toolbar {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 9;
            box-shadow: var(--mat-app-elevation-shadow-level-4, none);
        }

        .page-wrapper {
            padding-top: var(--mat-toolbar-standard-height, none);
        }
    }
}




::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;

    &:hover {
        background: #cccccc;
    }

    &:active {
        background: #888888;
    }
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;

    &:hover {
        background: #666666;
    }

    &:active {
        background: #333333;
    }
}

::-webkit-scrollbar-corner {
    background: transparent;
}

 
.theme-pagination {
    display: flex;
    align-items: center;
    place-content: center;
    margin: 0 auto;
    .ngx-pagination {
        margin-top: 16px;
        padding: 0;
        border: 1px solid var(--mdc-outlined-card-outline-color);
        border-radius: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        overflow: hidden;
        a,
        button {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 16px; 
            width: 100%;
        }
        li:not(.small-screen) {
            align-items: center;
            display: flex;
            margin-bottom: 0; 
            min-width: 40px;
            height: 100%; 
            &:not(:last-child) {
                border-right: 1px solid var(--mdc-outlined-card-outline-color);      
            }
            &:last-child {
                border: none;
            }           
        }
        li.current { 
            justify-content: center; 
            padding: 0;
        }
     
        .pagination-previous a:before,
        .pagination-previous.disabled:before {
            font-family: 'Material Icons';
            content: "\f1e6";
            font-size: 16px;
        }

        .pagination-next a:after,
        .pagination-next.disabled:after {
            font-family: 'Material Icons';
            content: "\f1df";
            font-size: 16px;
        }
    }
}

.video-container {
    video {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
}


.sticky-pagination {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background: var(--mdc-outlined-card-container-color);
}